var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "iframe-container-box" }, [
    _c(
      "div",
      { staticClass: "h100" },
      [
        _c(
          "rs-screen",
          [
            _c(
              "rs-screen-item",
              {
                attrs: { calc: true },
                model: {
                  value: _vm.tableHeight,
                  callback: function ($$v) {
                    _vm.tableHeight = $$v
                  },
                  expression: "tableHeight",
                },
              },
              [
                _c(
                  "render-table",
                  {
                    attrs: {
                      maxHeight: _vm.tableHeight - 50,
                      height: _vm.tableHeight - 50,
                      list: _vm.tableData,
                      columns: _vm.tableColumns,
                    },
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "action" }, slot: "action" },
                      [
                        _c(
                          "rs-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: { click: _vm.addReportCenterConfig },
                          },
                          [_vm._v("新增")]
                        ),
                      ],
                      1
                    ),
                    _c("rs-table-column", {
                      attrs: {
                        slot: "operation",
                        label: "操作",
                        width: "220",
                        fixed: "right",
                        align: "left",
                      },
                      slot: "operation",
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "rs-button",
                                {
                                  attrs: { size: "small", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.edit(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _c(
                                "rs-button",
                                {
                                  attrs: { size: "small", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.reset(scope)
                                    },
                                  },
                                },
                                [_vm._v("恢复默认")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "rs-screen-item",
              [
                _c("rs-pagination", {
                  attrs: {
                    "current-page": _vm.currentPage,
                    "page-sizes": [10, 20, 50, 100],
                    "page-size": _vm.pageSize,
                    layout: "total,prev, pager, next,sizes, jumper",
                    total: _vm.total,
                    background: "",
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }