<template>
  <div class="iframe-container-box">
    <div class="h100">
      <rs-screen>
        <rs-screen-item :calc="true" v-model="tableHeight">
          <render-table
              :maxHeight="tableHeight-50"
              :height="tableHeight-50"
              :list="tableData"
              :columns="tableColumns"
            >
            <div slot="action">
              <rs-button type="primary" size="mini" @click="addReportCenterConfig">新增</rs-button>
            </div>
            <rs-table-column slot="operation" label="操作" width="220" fixed="right" align="left">
              <template slot-scope="scope">
                <rs-button size="small" @click.stop="edit(scope.row)" type="text">编辑</rs-button>
                <rs-button size="small" @click.stop="reset(scope)" type="text">恢复默认</rs-button>
              </template>
            </rs-table-column>
          </render-table>
        </rs-screen-item>
        <rs-screen-item>
          <rs-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total,prev, pager, next,sizes, jumper"
            :total="total"
            background
          ></rs-pagination>
        </rs-screen-item>
      </rs-screen>
    </div>
  </div>
</template>
<script>
import Utils from '@/utils/utils'
import Api from '@/api/reportServer'
import Api1 from '@/api/api'
export default {
  data() {
    return {
      tableHeight:0,
      buttonLoading: false,
      tableData: [
      ],
      tableColumns:[
        {
          prop: 'mallName',
          label: '项目名称'
        },
        {
          prop: 'fieldName',
          label: '字段名称'
        },
        {
          prop: 'fieldLengthName',
          label: '字段长度'
        },
        {
          prop: 'numberDigit',
          label: '小数保留位数'
        },
        {
          prop: 'mantissaModeName',
          label: '尾数计算方式'
        },
        {
          slot: 'operation'
        },
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      searchForm: {
        mallCode: ''
      },
      ruleForm: [
        {
          slot: 'mallCode'
        },
        {
          slot: 'operation'
        }
      ]
    }
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      if (!this.searchForm.mallCode) {
        this.$message.warning('请先选择项目')
      } else {
        this.currentPage = 1
        this.getList()
      }
    },
    getList(params) {
      if (!params) {
        params = {
          pageSize: this.pageSize,
          pageNum: this.currentPage
        }
      }
      if (this.searchForm.mallCode) params.mallCode = this.searchForm.mallCode
      Api.reportConfigList(params).then(res => {
        this.tableData = res.data.data.records
        this.total = res.data.data.totalRecords
      })
    },
    handleRowClick(row) {
      this.$router.push('/editReportCenterConfig?id=' + row.id)
    },
    addReportCenterConfig() {
      this.$router.push('/addReportCenterConfig')
    },
    edit(row){
      console.log('row:',row)
      this.$router.push('/editReportCenterConfig?id='+ row.id)
    },
    reset(scope) {
      this.$confirmDialog({ title: '提示', textBody: `您确定要恢复默认吗？`, width: '35%', iconType: 'warning',btnType:0,btnText: '确定恢复',})
        .then(async () => {
          let params = {
            id: scope.row.id
          }
          let res = await Api.reportConfigDefault(params)
          if (res.data.code === 200) {
            this.$message({
              message: '恢复默认成功',
              type: 'success'
            })
            this.getList()
          }
        })
    },
    clear() {
      this.searchForm.mallCode = ''
    },
    handleCurrentChange(val) {
      this.currentPage = val
      let params = {
        pageSize: this.pageSize,
        pageNum: this.currentPage
      }
      this.getList(params)
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .rs-form-item--small.rs-form-item {
  margin-bottom: 0;
}
</style>
